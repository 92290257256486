import React, { useCallback, useEffect } from 'react';
import BattlesInfoModal from '../../common/InfoModal';
import { wire } from 'react-hot-wire';
import UserProfileService from 'services/user/UserProfileService';
import useStartNewDuel from './hook/useStartNewDuel';
import BattlesService from 'services/battles/BattlesService';
import Loader from '../../../commons/_/loader/Loader';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button } from '../../../_/common/button/Button';
import BrowserHistoryService from 'services/application/BrowserHistoryService';
import { useQueryClient } from 'react-query';
import { getBattleListQueryKey } from '../../hook/api/useGetBattleList';
import { useLocation } from 'react-router-dom';

export type NewDuelModalProps = { isOpen: boolean; onClose: () => void };
export type NewDuelModalWiredProps = NewDuelModalProps & {
	'services.userProfileService': UserProfileService;
	'services.battlesService': BattlesService;
	'services.browserHistoryService': BrowserHistoryService;
};

export const NewDuelModal = ({
	isOpen,
	onClose,
	'services.userProfileService': userProfileService,
	'services.browserHistoryService': browserHistoryService,
	...props
}: NewDuelModalWiredProps) => {
	const location = useLocation();
	const queryClient = useQueryClient();
	const startNewDuel = useStartNewDuel(props);
	const onButtonClick = useCallback(() => {
		onClose();

		if (startNewDuel.isSuccess) {
			if (location.pathname === '/battles/waiting') {
				queryClient.resetQueries(getBattleListQueryKey);
			} else {
				browserHistoryService.push('/battles/waiting');
			}
		}
	}, [onClose, browserHistoryService, location, startNewDuel.isSuccess, queryClient]);

	useEffect(() => {
		if (isOpen && !startNewDuel.isSuccess && !startNewDuel.isLoading && !startNewDuel.isError) {
			startNewDuel.mutate();
		} else if (!isOpen) {
			startNewDuel.reset();
		}
	}, [isOpen]);

	return (
		<BattlesInfoModal isOpen={isOpen}>
			{startNewDuel.isLoading ? (
				<div className="d-flex justify--center">
					<Loader fixed={false} />
				</div>
			) : (
				<div className="w-100 d-flex flex-column justify-content-center align-items-center">
					<div className="fs-5 font-weight-bold text-center mb-2">
						<FormattedMessage
							id={`battles.start-new-game.title`}
							values={{
								name: userProfileService.user()?.userProfile.forename(),
							}}
						/>
					</div>
					<div className="color--gray text-center">
						<FormattedHTMLMessage
							id={`battles.start-new-game.${startNewDuel.isSuccess ? 'success' : 'fail'}.text`}
						/>
					</div>

					<Button
						color="interactive"
						onClick={() => onButtonClick()}
						className="mt-4 battlesModal__action-button"
					>
						<FormattedMessage
							id={`battles.start-new-game.${startNewDuel.isSuccess ? 'success' : 'fail'}.button`}
						/>
					</Button>
				</div>
			)}
		</BattlesInfoModal>
	);
};

export default wire<NewDuelModalWiredProps, NewDuelModalProps>(
	['services.userProfileService', 'services.battlesService', 'services.browserHistoryService'],
	NewDuelModal
);
