import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import { Formik } from 'formik';

import { schema } from './SetPassword.schema';

import SetPassword from './SetPassword.template';

export class SetPasswordComponent extends PureComponent {
	render() {
		return (
			<Formik
				initialValues={{
					password: '',
					confirmPassword: '',
				}}
				validationSchema={schema}
				onSubmit={(...args) => this.setPassword(...args)}
				render={props => <SetPassword {...props} {...this.props} />}
			/>
		);
	}

	setPassword(values, actions) {
		const { integration, type, token, authorizationCode, identifier, onAuthorizeError } = this.props;
		let method = null;
		let data = {
			id: integration.id,
			password: values.password,
		};

		if (type === 'email') {
			method = 'resetPasswordCompleteEmail';
			data.reset_token = token;
		} else if (type === 'phone') {
			method = 'resetPasswordCompletePhone';
			data.authorization_code = authorizationCode;
			data.identifier = identifier;
		}

		if (values.password !== values.confirmPassword) {
			actions.setFieldError('confirmPassword', {
				messageId: 'passwords-differ',
			});
			actions.setSubmitting(false);

			return;
		}

		this.props['services.importedIdIntegrationService'][method](data).catch(error => {
			if (onAuthorizeError && error === 'invalid-password-reset-token') {
				onAuthorizeError(error);
			} else {
				actions.setFieldError('confirmPassword', error);
			}

			actions.setSubmitting(false);
		});
	}
}

export default wire(['services.importedIdIntegrationService'], SetPasswordComponent);
