import { useEffect } from 'react';
import { isProduction } from 'utils/env/isProduction';

export const Learning = () => {
	useEffect(() => {
		window.location.href = isProduction ? '/panel/learning' : `${process.env.REACT_APP_PANEL_HOST}/learning`;
	}, []);

	return null;
};
export default Learning;
