import { Component } from 'react';

export default function(applicationService, browserHistoryService, platformService) {
	return class WrapperMenu extends Component {
		/**
		 *         ↑ Vertical movement (scroll) (deltaY)
		 *         |
		 *    90°  |
		 *         |
		 *         |   Too steep (≥ 60°) → IGNORED (scroll)
		 *         |  /
		 *         | / 60° (Threshold)
		 *         |/
		 * --------+----------------→ Horizontal movement (swipe) (deltaX)
		 *     (start)     Allowed Swipe (< 60°)
		 */
		swipeAngle = 30;
		swipeIgnoreBelow = 10; // px
		swpieDistance = 50; // px
		swipeEdgeArea = 30; // px

		constructor(...args) {
			super(...args);

			this.state = {
				isOpen: false, // false / true
				currentSection: null, // null | "menu" | "language"
				isSwiping: false,
				touchStartX: 0,
				touchEndX: 0,
				touchStartY: 0,
				touchEndY: 0,
			};
		}

		openMenu(event, name) {
			event.stopPropagation();

			this.setState({
				isOpen: true,
				currentSection: name,
			});
		}

		closeMenu(event) {
			event.stopPropagation();

			this.setState({
				isOpen: false,
			});
		}

		componentDidMount() {
			document.addEventListener('touchstart', this.handleTouchStart, { passive: true });
			document.addEventListener('touchmove', this.handleTouchMove, { passive: true });
			document.addEventListener('touchend', this.handleTouchEnd);
		}

		handleTouchStart = e => {
			const touchX = e.touches[0].clientX;
			const screenWidth = window.innerWidth;
			const fromRight = screenWidth - touchX;

			if (fromRight > this.swipeEdgeArea && !this.state.isOpen) {
				return;
			}

			this.setState({
				touchStartX: e.touches[0].clientX,
				touchEndX: e.touches[0].clientX,
				touchStartY: e.touches[0].clientY,
				touchEndY: e.touches[0].clientY,
				isSwiping: false,
			});
		};

		handleTouchMove = event => {
			this.setState(state => {
				const touchEndX = event.touches[0].clientX;
				const touchEndY = event.touches[0].clientY;
				const deltaX = Math.abs(touchEndX - state.touchStartX);
				const deltaY = Math.abs(touchEndY - state.touchStartY);

				const angle = Math.abs(Math.atan2(deltaY, deltaX) * (180 / Math.PI));

				const newState = { ...state, touchEndX, touchEndY, swipeAngle: angle };

				if (deltaX > this.swipeIgnoreBelow && angle < this.swipeAngle) {
					newState.isSwiping = true;
				}

				return newState;
			});
		};

		handleTouchEnd = () => {
			this.setState(state => {
				const { isSwiping, isOpen, touchStartX, touchEndX, swipeAngle } = state;
				const swipeDistanceX = touchEndX - touchStartX;

				if (!isSwiping || swipeAngle >= this.swipeAngle) return state;

				if (!isOpen && swipeDistanceX < -this.swpieDistance) {
					return {
						...state,
						isOpen: true,
						currentSection: 'menu',
						touchStartX: 0,
						touchEndX: 0,
						touchStartY: 0,
						touchEndY: 0,
						isSwiping: false,
					};
				} else if (isOpen && swipeDistanceX > this.swpieDistance) {
					return {
						...state,
						isOpen: false,
						touchStartX: 0,
						touchEndX: 0,
						touchStartY: 0,
						touchEndY: 0,
						isSwiping: false,
					};
				}

				return {
					...state,
					touchStartX: 0,
					touchEndX: 0,
					touchStartY: 0,
					touchEndY: 0,
					isSwiping: false,
				};
			});
		};

		componentWillUnmount() {
			document.removeEventListener('touchstart', this.handleTouchStart);
			document.removeEventListener('touchmove', this.handleTouchMove);
			document.removeEventListener('touchend', this.handleTouchEnd);
		}
	};
}
