import React from 'react';
import { ErrorMessage, Field, Form } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';

export default injectIntl(({ intl, errors, touched, isSubmitting, isValid }) => (
	<div className="integration">
		<Form>
			<h2>
				<FormattedMessage id="auth.reset-password.action.set-password.title.reset" tagName={React.Fragment} />
			</h2>
			<div
				className={classnames('field', {
					invalid: errors.password && touched.password,
				})}
			>
				<Field
					name="password"
					render={({ field }) => (
						<input
							{...field}
							type="password"
							autoComplete="password"
							placeholder={intl.formatMessage({
								id: 'auth.reset-password.action.set-password.field.password.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="password"
					render={error => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${error}`} />
						</div>
					)}
				/>
			</div>
			<div
				className={classnames('field', {
					invalid: errors.confirmPassword && touched.confirmPassword,
				})}
			>
				<Field
					name="confirmPassword"
					render={({ field }) => (
						<input
							{...field}
							type="password"
							placeholder={intl.formatMessage({
								id: 'auth.reset-password.action.set-password.field.confirm-password.placeholder',
							})}
						/>
					)}
				/>
				<ErrorMessage
					name="confirmPassword"
					render={({ messageId, data: values }) => (
						<div className="error">
							<FormattedMessage id={`auth.form.error.${messageId}`} values={values} />
						</div>
					)}
				/>
			</div>
			<button
				className={classnames('btn__basic btn__basic--fullWidth', {
					'btn__basic--disabled': !isValid,
					loading: isSubmitting,
				})}
				type="submit"
			>
				<FormattedMessage id="auth.reset-password.action.set-password.button.reset" />
			</button>
		</Form>
	</div>
));
